.container :global .glider-slide {
    @apply flex items-center h-full;
}

.container :global .glider::-webkit-scrollbar {
    @apply h-0;
    scrollbar-height: none;
}

.container :global .glider {
    scrollbar-width: none;
}

.snap {
    scroll-snap-type: x mandatory;
}

.snap :global .glider-slide {
    scroll-snap-align: var(--snapAlign, start);
    scroll-snap-stop: var(--snapStop, always);
}

/* target safari < 15 */
@supports (not (scroll-snap-stop: normal)) and (not (-moz-appearance: none)) {
    /* set same snap behavior on safari < 14 as changing
    it brings the scroll position back to start  */
    .snap-animating {
        scroll-snap-type: x mandatory;
    }

    .snap-animating :global .glider-slide {
        scroll-snap-align: center;
    }
}
