.aspect-ratio-element {
    aspect-ratio: 1;
}

@supports not (aspect-ratio: 1/1) {
    .aspect-ratio-container {
        @apply relative pt-[100%] block;
    }

    .aspect-ratio-element {
        @apply absolute left-0 top-0;
    }
}

@media (min-width: theme(screens.lg)) {
    .aspect-ratio-container {
        @apply static pt-0 inline;
    }

    .aspect-ratio-element {
        @apply aspect-auto static;
    }
}
