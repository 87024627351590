.section-title {
    font-feature-settings: 'tnum' on, 'lnum' on;
}

.title-container {
    height: max-content;
}

.description-carousel-container :global .glider-slide {
    scroll-snap-align: center !important;
}
