.scroll-container {
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
}

.scroll-container > * {
    scroll-snap-align: center;
}

.scroll-container::-webkit-scrollbar {
    @apply appearance-none h-0;
}

.scroll-container::-moz-scrollbar {
    @apply appearance-none h-0;
}
