/* the grid cell max values were obtained
from hit and trial method, these values allow
for a 2 column grid in screens <= sm even
when using auto-fit, remember to test in
small screen sizes when changing them */

.icons-grid {
    @apply grid gap-6 lg:gap-11;
}

.icons-scroll {
    @apply flex overflow-x-auto;
    scrollbar-width: none;
}

.icons-scroll > * {
    @apply shrink-0 grow px-6 lg:px-7 first:pl-0 last:pr-0;
}

.icons-scroll::-webkit-scrollbar {
    @apply h-0;
    scrollbar-height: none;
    scrollbar-width: none;
}

@media (max-width: theme(screens.sm)) {
    .icons-scroll {
        @apply block;
    }
    .icons-scroll > * {
        @apply pl-0 pb-2;
    }
    .icons-scroll img {
        @apply h-9;
    }
}
@media (min-width: theme(screens.xs)) {
    .icons-grid {
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    }
}

@media (min-width: theme(screens.sm)) {
    .icons-grid {
        grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
    }
    .icons-scroll {
        @apply flex overflow-x-auto;
        scrollbar-width: none;
    }
    .icons-scroll > * {
        @apply pl-0;
    }
}
