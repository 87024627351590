@value thumbHeight: 2.75rem;
@value thumbWidth: 2.75rem;

/* Range input styles */
.input-range {
    --trackColor: theme('colors.black.DEFAULT');
    --thumbColor: theme('colors.black.DEFAULT');
}

.input-range:disabled {
    --trackColor: theme('colors.gray.DEFAULT');
    --thumbColor: theme('colors.gray.DEFAULT');
}

/* .input-range range */
.input-range {
    /* appearance-none: Hides the slider so that custom slider can be made */
    /* bg-transparent: Otherwise white in Chrome */
    @apply appearance-none bg-transparent w-full relative;
}

.input-range::-webkit-slider-thumb {
    @apply appearance-none;
    height: var(--thumbHeight);
    width: var(--thumbWidth);
}

.input-range::-ms-track {
    @apply w-full bg-transparent border-transparent text-transparent;
}

/* Special styling for WebKit/Blink */
.input-range::-webkit-slider-thumb {
    @apply appearance-none rounded-full;
    background: var(--thumbColor);
    height: var(--thumbHeight);
    width: var(--thumbWidth);
    margin-top: calc(var(--thumbHeight) / 2 * -1);
}

/* All the same stuff for Firefox */
.input-range::-moz-range-thumb {
    @apply rounded-full border-0;
    background: var(--thumbColor);
    height: var(--thumbHeight);
    width: var(--thumbWidth);
}

/* All the same stuff for IE */
.input-range::-ms-thumb {
    @apply rounded-full;
    background: var(--thumbColor);
    height: var(--thumbHeight);
    width: var(--thumbWidth);
}

/* range track */
.input-range::-webkit-slider-runnable-track {
    @apply w-full h-px;
    background: var(--trackColor);
}

.input-range::-moz-range-track {
    @apply w-full h-px;
    background: var(--trackColor);
}

.input-range::-ms-track {
    @apply w-full text-transparent h-px;
    background: var(--trackColor);
}
