@value dotHeight: 0.75rem;
@value dotWidth: 0.75rem;
@value trackWidth: 1px;

.dot-slider {
    @apply absolute left-0;

    /* don't grow to the end of section */
    height: calc(100% - 15rem);
}

.dot-track {
    @apply bg-black;

    width: trackWidth;
    height: calc(100% - dotHeight);
}

.dot {
    @apply bg-black rounded-full sticky top-1/2;

    width: dotWidth;
    height: dotHeight;

    transform: translateX(calc(-50% + trackWidth/2));
}

@media (min-width: theme(screens.md)) {
    .dot-slider {
        left: calc(50% + dotWidth/2);
    }
}
