/* border in clipped area is achieved by
creating a slightly larger clipped element and
placing it behind the main clipped element */
.clip-path-border:before {
    content: '';
    position: absolute;
    width: calc(100% + var(--clip-border-width));
    height: calc(100% + var(--clip-border-width));

    background: var(--clip-border-color, theme(colors.black.DEFAULT));

    --translate: calc(var(--clip-border-width) / 2 * -1);

    clip-path: url(#token-clip-path);
    transform: translate(var(--translate), var(--translate));
}
