.loop-left {
    animation: loop-left var(--loopDuration) linear infinite;
}

.loop-right {
    animation: loop-right var(--loopDuration) linear infinite;
}

@media (prefers-reduced-motion: reduce) {
    .loop-left,
    .loop-right {
        animation: none;
    }
}

@keyframes loop-left {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc((var(--containerWidth) / 2) * -1));
    }
}

@keyframes loop-right {
    0% {
        transform: translateX(calc((var(--containerWidth) / 2) * -1));
    }
    100% {
        transform: translateX(0);
    }
}
