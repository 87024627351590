.table-cell-with-icon picture + p {
    @apply pt-1 pl-12 mb-5 lg:mb-7.5;
}

.progress-label {
    left: max(0px, min(calc(100% * var(--progress) - var(--labelWidth) / 2), calc(100% - var(--labelWidth))));
}

.progress-line {
    left: calc(100% * var(--progress) - var(--labelWidth) / 2);
}
