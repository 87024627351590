.input-range[type='range'] {
    --trackColor: theme('colors.black.DEFAULT');
    --thumbColor: theme('colors.black.DEFAULT');
}

.input-range[type='range']:disabled {
    --trackColor: theme('colors.gray.DEFAULT');
    --thumbColor: theme('colors.gray.DEFAULT');
}

/* .input-range range */
.input-range[type='range'] {
    /* appearance-none: Hides the slider so that custom slider can be made */
    /* bg-transparent: Otherwise white in Chrome */
    @apply appearance-none bg-transparent h-2;
}

.input-range[type='range']::-webkit-slider-thumb {
    @apply appearance-none;
}

.input-range[type='range']::-ms-track {
    @apply w-full bg-transparent border-transparent text-transparent;
}

/* Special styling for WebKit/Blink */
.input-range[type='range']::-webkit-slider-thumb {
    @apply appearance-none w-3 h-3 rounded-full;
    /* @apply appearance-none w-3 h-3 rounded-full bg-black; */
    margin-top: -5.5px;
    background: var(--thumbColor);
}

/* All the same stuff for Firefox */
.input-range[type='range']::-moz-range-thumb {
    /* @apply h-3 w-3 rounded-full bg-black border-0; */
    @apply h-3 w-3 rounded-full border-0;
    background: var(--thumbColor);
}

/* All the same stuff for IE */
.input-range[type='range']::-ms-thumb {
    /* @apply h-3 w-3 rounded-full bg-black; */
    @apply h-3 w-3 rounded-full;
    background: var(--thumbColor);
}

/* range track */
.input-range[type='range']::-webkit-slider-runnable-track {
    /* @apply bg-black w-full h-px; */
    @apply w-full h-px;
    background: var(--trackColor);
}

.input-range[type='range']::-moz-range-track {
    /* @apply bg-black w-full h-px; */
    @apply w-full h-px;
    background: var(--trackColor);
}

.input-range[type='range']::-ms-track {
    /* @apply bg-black w-full text-transparent h-px; */
    @apply w-full text-transparent h-px;
    background: var(--trackColor);
}
