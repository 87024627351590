.section-grid {
    grid-template-rows: 540px 1fr;
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

@media (min-width: theme(screens.lg)) {
    .section-grid {
        grid-template-rows: 640px 1fr;
        grid-template-columns: 1fr 1fr;
    }
}
