.scrollable-container {
    @apply overflow-x-auto;
    scrollbar-width: none;
}

.scrollable-container--snap {
    scroll-snap-type: x mandatory;
}

.scrollable-container::-webkit-scrollbar {
    @apply h-0;
    scrollbar-width: none;
}

.scrollable-container--snap > div > * {
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

.progress-bar {
    overflow: hidden;

    --trackColor: theme('colors.gray.medium');
    --accentColor: theme('colors.black.DEFAULT');

    --progress: 0;

    --spaceLeft: 10px;
    --width: calc((100% / var(--totalMobileVideos)) - var(--spaceLeft));

    width: var(--width);
    left: calc((var(--width) + var(--spaceLeft)) * var(--position));
}

.progress-bar:disabled {
    @apply opacity-30;
}

/* style input[type='range'] ui */
.progress-bar {
    /* appearance-none: Hides the slider so that custom slider can be made */
    /* bg-transparent: Otherwise white in Chrome */
    @apply appearance-none bg-transparent;
}

.progress-bar::-webkit-slider-thumb {
    @apply appearance-none h-full w-px bg-transparent;
}

.progress-bar::-ms-track {
    @apply w-full bg-transparent border-transparent text-transparent;
}

/* All the same stuff for Firefox */
.progress-bar::-moz-range-thumb {
    @apply appearance-none h-0 w-0 border-none;
}

.progress-bar::-moz-range-progress {
    background-color: var(--accentColor);
}

/* All the same stuff for IE */
.progress-bar::-ms-thumb {
    @apply appearance-none;
}

/* range track */
.progress-bar::-webkit-slider-runnable-track {
    @apply w-full h-1;
    background: linear-gradient(
        to right,
        var(--accentColor) 0%,
        var(--accentColor) var(--progress),
        var(--trackColor) var(--progress),
        var(--trackColor) 100%
    );
}

.progress-bar::-moz-range-track {
    @apply w-full h-1;
    background: var(--trackColor);
}

.progress-bar::-ms-track {
    @apply w-full text-transparent h-1;
    background: var(--trackColor);
}

.progress-bar::-ms-fill-lower {
    background-color: var(--accentColor);
}

@media (min-width: theme(screens.lg)) {
    .progress-bar {
        --trackColor: rgba(255, 255, 255, 0.3);
        --accentColor: theme('colors.white.DEFAULT');
    }
}

@media (hover: hover) {
    .progress-bar:hover {
        --trackColor: theme('colors.gray.medium');
        --accentColor: theme('colors.gray.medium');
    }
}
