.flip-card {
    @apply w-full bg-transparent;
    height: 250px;
    perspective: 1000px; /* 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
    @apply relative w-full h-full transition-transform duration-700;
    transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
    @apply absolute w-full h-full;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0); /* needed for safari 12.1 */
}

.flip-card-back {
    transform: rotateY(180deg);
    /* transparent outline fixes ios safari black background bug */
    @apply outline-none;
}
