.progress-bar {
    @apply overflow-hidden;
    background: var(--progressTrackColor);
}

.progress-bar::after {
    @apply h-full block;
    content: '';
    background: var(--progressColor);
    transform: translateX(calc(var(--progress) - 100%));
}

.progress-bar--animated::after {
    transition: transform 0.25s ease-out;
}
