.section-grid {
    @apply grid;
}

.illustration {
    @apply ml-auto -mt-5;
}

@media (min-width: theme(screens.lg)) {
    .section-grid {
        @apply grid-cols-2;
    }

    .illustration--right {
        transform: rotateY(180deg) translateX(80%);
    }

    .illustration--left {
        @apply -right-2.5;
    }
}

@media (min-width: theme(screens.xl)) {
    .section-grid--image-on-left {
        grid-template-columns: 1fr 1.5fr;
    }

    .section-grid--image-on-right {
        grid-template-columns: 1.5fr 1fr;
    }
}
