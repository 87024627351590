.header h2 {
    @apply text-3.5xl lg:text-6.5xl mb-0 mt-11 lg:mt-16;
}

.header h2 b {
    @apply font-normal;
}

.cover-image {
    height: 355px;
}

@media (min-width: theme(screens.sm)) {
    .cover-image {
        height: 420px;
    }
}

@media (min-width: theme(screens.md)) {
    .cover-image {
        height: 470px;
    }
}

@media (min-width: theme(screens.lg)) {
    .cover-image {
        @apply h-full;
    }
}
