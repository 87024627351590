.aspect-ratio-element {
    @apply object-cover overflow-hidden;
    aspect-ratio: 1;
}

@supports not (aspect-ratio: 1 / 1) {
    .aspect-ratio-container {
        /* 1 aspect ratio */
        @apply relative pt-[100%];
    }

    .aspect-ratio-element {
        @apply absolute top-0 right-0 bottom-0 left-0;
    }
}
