.comparison-table-section-grid {
    @apply grid;
    background: linear-gradient(180deg, theme('colors.gray.lighter') 14.5%, theme('colors.blue.rx') 73%);
}

.comparison-table-grid {
    @apply grid gap-x-2.5 gap-y-8;
}

@media (min-width: theme(screens.lg)) {
    .comparison-table-section-grid {
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    }

    .comparison-table-grid {
        grid-template-columns: auto 1fr;
    }

    .comparison-table-grid-single-column {
        grid-template-columns: 1fr;
    }
}
