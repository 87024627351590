.markdown {
    @apply leading-relaxed;
}

.markdown ul,
.markdown ol,
.markdown blockquote {
    @apply ml-12;
}

.markdown h2 {
    @apply text-2xl mt-12 mb-4 leading-snug;
}

.markdown h3 {
    @apply text-lg mt-8 mb-4 leading-snug;
}
