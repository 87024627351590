.grid-cell:nth-child(3n + 1) {
    grid-row: var(--rowPosition) / span 2;
}

@media (min-width: theme(screens.lg)) {
    .grid-cell:nth-child(3n-1) {
        grid-column: 2;
        grid-row: var(--rowPosition);
    }

    .grid-cell:nth-child(3n) {
        grid-column: 2;
    }

    .grid-cell:nth-child(6n-1) {
        grid-column: 4;
    }

    .grid-cell:nth-child(6n) {
        grid-column: 4;
    }

    .grid-cell:nth-child(6n + 1) {
        grid-column: 1;
    }

    .grid-cell:nth-child(6n + 4) {
        grid-column: 3;
    }
}
