@value cardAspectRatio: 5/4;

.reviews-container {
    @apply -mx-5 sm:-mx-6 lg:-mx-15;
}

.reviews-list {
    @apply flex;
    @apply px-5 sm:px-6 lg:px-15 box-content;
}

.reviews-list > * {
    @apply shrink-0 grow-0;
    @apply mr-3.5 lg:mr-8 last:mr-0 lg:last:mr-0;

    width: var(--cardWidth, 275px);
    height: calc(var(--cardWidth, 275px) * cardAspectRatio);
}

.range-input {
    @apply px-5 sm:px-6 lg:px-15;
}

@media (min-width: theme(screens.lg)) {
    .reviews-list > * {
        width: var(--cardWidthLg, 370px);
        height: calc(var(--cardWidthLg, 275px) * cardAspectRatio);
    }
}

/* Safari 10.1+ */
@media not all and (min-resolution: 0.001dpcm) {
    @media {
        /* adds space at the end of scrollable list */
        .reviews-list::after {
            content: '';
            @apply pr-5 sm:pr-6 lg:pr-8;
        }
    }
}
