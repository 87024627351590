.reference {
    word-break: break-word;
}

@media (min-width: theme(screens.lg)) {
    .references-container {
        columns: 2;
        column-gap: 3.75rem;
    }
}
