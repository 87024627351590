.aspect-ratio-element {
    @apply object-cover;
    aspect-ratio: 1;
}

/* only show 3 items in mobile screens */
.grid > :nth-child(n + 4) {
    /* select all children after 3rd child */
    @apply hidden;
}

@media (min-width: theme(screens.lg)) {
    .grid > :nth-child(n + 4) {
        /* select all children after 3rd child */
        @apply flex;
    }
}

@supports not (aspect-ratio: 1 / 1) {
    .aspect-ratio-container {
        /* 1 aspect ratio */
        @apply relative pt-[100%];
    }

    .aspect-ratio-element {
        @apply absolute top-0 right-0 bottom-0 left-0;
    }
}
