.sliderThumb {
    /* Applies to #minmax-range::-webkit-slider-thumb */
    background-color: #ffffff;
}

.sliderThumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    background: #4caf50;
    cursor: pointer;
    border-radius: 0.375rem;
}
