.small-input {
    width: 150px;
}

.modal-content {
    min-height: 150px;
}

.button {
    min-width: 130px;
}

.button:focus-visible {
    @apply ring-gray !important;
}
