@media (min-width: theme(screens.lg)) {
    .columns {
        columns: 2;
        column-gap: 5rem;
    }
}

@media (min-width: theme(screens.xl)) {
    .columns {
        column-gap: 7.5rem;
    }
}
